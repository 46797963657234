body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.validation {
  float: left;
  color: #CF0E0E;
}

p.validation {
  padding:10px;
  font-size: 13px;
}

.bx {
  font-family: 'boxicons'!important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img-carousel {
  height: '160px';
  color: '#fff';
  line-height: '160px';
  text-align: 'center';
  background: '#364d79';
}
